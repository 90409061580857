/*
 * @Author: mengying.yao
 * @Email: mengying.yao@medbanks.cn
 * @Date: 2020-09-14 14:57:16
 * @Last Modified by: mengying.yao
 * @Last Modified time: 2020-10-12 15:50:11
 * @Description: Description
 * @Route: Route
 */


<template>
  <div class="source-list">
    <h2 class="title">
      来源管理
    </h2>
    <el-input v-model="channelName"
              @keydown.enter.native="getList"
              style="width: 300px;"
              suffix-icon="el-icon-search"
              clearable
              placeholder="请输入" />

    <el-tree ref="sourceList"
             :data="sourceList"
             :props="{
               label : (data, node) => data.channelCode + ' ' + data.channelName,
               children : 'customerOriginChildrenRespDTOList',
               isLeaf : 'isLeaf'
             }"
             :load="getNode"
             class="tree"
             node-key="channelCode"
             lazy>
      <span slot-scope="{ node, data }"
            class="custom-tree-node">
        <el-tooltip :content="node.label"
                    class="tree_label"
                    effect="dark"
                    placement="top-start">
          <span class="tree_label">{{ node.label }}</span>
        </el-tooltip>

        <span>
          <el-button v-show="data.level < 3"
                     @click.stop="addChild(node , data)"
                     type="text"
                     size="mini">
            添加下级
          </el-button>
          <el-button v-show="data.level !== 1"
                     @click.stop="edit(node , data)"
                     type="text"
                     size="mini">
            编辑
          </el-button>
          <!-- v-show="node.level !== 1" -->
          <el-button v-show="data.level !== 1"
                     @click.stop="remove(node, data)"
                     type="text"
                     size="mini">
            删除
          </el-button>
        </span>
      </span>
    </el-tree>
    <el-dialog :visible.sync="showDialog"
               title="编辑来源"
               width="30%">
      <el-form ref="sourceForm"
               :rules="rules"
               :model="sourceData"
               label-width="80px">
        <el-form-item label="上级名称"
                      required>
          <el-input v-model="sourceData.parentName"
                    disabled
                    autocomplete="off" />
        </el-form-item>
        <el-form-item label="来源名称"
                      prop="channelName">
          <el-input v-model="sourceData.channelName"
                    @input="changeParentName"
                    autocomplete="off" />
        </el-form-item>
        <el-form-item label="来源编码"
                      prop="channelCode">
          <el-input v-model="sourceData.channelCode"
                    :disabled="editing"
                    autocomplete="off" />
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="showDialog = false">
          取 消
        </el-button>
        <el-button @click="saveSourceData"
                   type="primary">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pyfl from 'pyfl';
import { question } from '@/api';

export default {
  data() {
    const checkChannelCode = function checkChannelCode(rule, value, callback) {
      const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g');
      if (reg.test(value)) {
        callback(new Error('来源编码不能包括中文字符'));
      } else {
        callback();
      }
    };
    return {
      channelName: '',
      showDialog: false,
      sourceData: {
        parentName: '',
        parentId: '',
        channelName: '',
        channelCode: '',
        level: 0,
      },
      rules: {
        channelName: [
          {
            required: true,
            message: '请输入来源名称',
            target: 'blur',
          },
        ],
        channelCode: [
          {
            required: true,
            message: '请输入来源编码',
            target: 'blur',
          },
          { validator: checkChannelCode, trigger: 'blur' },
        ],
      },
      sourceList: [],
      currentNode: null,
      editing: false, // 是否是编辑
    };
  },
  // created() {
  //     this.getList();
  // },
  methods: {
    getNode(node, resolve) {
      return question
        .getOriginList({
          channelCode: node.data.channelCode,
        })
        .then((res) => {
          const { data } = res;
          resolve(data || []);
        });
    },
    getList(e) {
      const channelNames = e.target.value;
      if (channelNames) {
        question
          .searchOrigin({
            channelNames,
          })
          .then((res) => {
            if (res.data) {
              this.sourceList = res.data.map((item) => {
                // eslint-disable-next-line no-param-reassign
                item.isLeaf = true;
                return item;
              });
            } else {
              this.sourceList = [];
            }
          }).catch()
      } else {
        question.getOriginList().then((res) => {
          this.sourceList = res.data;
        });
      }
    },
    changeParentName(value) {
      if (!this.editing) {
        this.sourceData.channelCode = `${this.sourceData.parentId}_${pyfl(value)}`;
      }
    },
    addChild(node, data) {
      this.sourceData = {
        parentId: data.channelCode,
        parentName: data.channelName,
        level: data.level + 1,
        channelName: '',
        channelCode: '',
      };

      this.$nextTick(() => {
        if (this.$refs.sourceForm) {
          this.$refs.sourceForm.clearValidate();
        }
      });

      this.showDialog = true;
      this.editing = false;
      this.currentNode = node;
    },
    saveSourceData() {
      this.$refs.sourceForm.validate((valid) => {
        if (valid) {
          question.addOriginMess(this.sourceData).then((res) => {
            this.$message.success('保存成功');
            this.showDialog = false;
            if (!this.editing) {
              this.$refs.sourceList.append(
                {
                  ...res.data,
                  customerOriginChildrenRespDTOList: [],
                },
                this.currentNode,
              );
            } else {
              this.currentNode.data = res.data;
            }
          });
        }
      });
    },
    edit(node, data) {
      this.sourceData = JSON.parse(JSON.stringify(data));
      this.currentNode = node;
      this.editing = true;
      this.showDialog = true;
    },
    remove(node, data) {
      this.$confirm(`确定要删除${data.channelName}吗`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        question.delkOrigin(data.id).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
          this.$refs.sourceList.remove(node);
        });
      }).catch()
    },
  },
};
</script>

<style lang="scss" scoped>
.tree {
  margin-top: 20px;
  width: 400px;
}
.tree_label {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
